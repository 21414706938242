html, body {
  margin: 0;
  padding: 0;
  height: 100%;
   
}

.error_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; 
  background-color: #2c2c2c; 
  text-align: center;
  gap: 20px;
  padding: 20px;
}

h1 {
  margin: 0;
}

.error_heading {
  color: #ffffff; 
  font-size: 36px; 
  font-weight: 700;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.error_subheading {
  color: #d3d3d3; 
  text-align: center;
  font-size: 20px; 
  font-weight: 400;
  line-height: 1.6;
  max-width: 600px;
  margin: 0px;
}

.error_btn_area {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin-top: 20px;
}

.error_btn_area a {
  text-decoration: none;
}

button {
  padding: 10px 30px;
  font-size: 14px;
  font-weight: 600;
  background-color: #e74c3c;
  color: #fff;
  border: 2px solid #e74c3c;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  text-transform:uppercase
}

button:hover {
  background-color: #e74c3c;
  transform: translateY(-3px);
}

button:active {
  transform: translateY(0);
}

h1:first-child {
  font-size: 160px;
    color: #e74c3c;
    font-weight: bold;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    line-height: 100%;
}



/* Mobile Responsiveness */
@media (max-width: 768px) {
  .error_heading {
    font-size: 28px;
  }

  h1:first-child {
    font-size: 56px; 
  }

  .error_subheading {
    font-size: 14px; 
  }

  button {
    padding: 8px 25px; 
    font-size: 12px;
  }

  .error_btn_area {
    gap: 10px; 
  }
}

@media (max-width: 480px) {
  .error_heading {
    font-size: 24px; 
  }

  h1:first-child {
    font-size: 48px; 
  }

  .error_subheading {
    font-size: 12px; 
  }

  button {
    padding: 6px 20px; 
    font-size: 10px;
  }
}
